<template>
  <div class="outer">
    <v-sheet v-if="listCarriTerminal && listCarriTerminal.length > 0">
      <div class="text-h5 font-weight-regular">Sposta tutti</div>
      <v-icon class="uti-icon" @click="spostaTuttiCarri()"
        >mdi-arrow-down-box</v-icon
      >&nbsp;
    </v-sheet>
    <br/>
    <div class="text-h5 font-weight-regular">LISTA CARRI IN TERMINAL</div>
    <br />
    <v-sheet
      v-for="carroConvoglio in listCarriTerminal"
      :key="generaChiaveCarroConvoglio(carroConvoglio.id, true)"
    >
      <WagonManovreView
        :tripId="tripId"
        :viaggioTreno="viaggioTreno"
        :convoglioId="convoglioId"
        :carroConvoglio="carroConvoglio"
        :isSender="true"
      />
    </v-sheet>
    <v-sheet v-if="!listCarriTerminal || listCarriTerminal.length == 0">
      <div class="text-h5 font-weight-regular">NESSUN CARRO TROVATO</div>
    </v-sheet>
    <br />
    <div class="text-h5 font-weight-regular">CONVOGLIO</div>
    <br />
    <v-sheet
      v-for="carroConvoglio in listCarriConvoglio"
      :key="generaChiaveCarroConvoglio(carroConvoglio.id, false)"
    >
      <WagonManovreView
        :tripId="tripId"
        :viaggioTreno="viaggioTreno"
        :convoglioId="convoglioId"
        :carroConvoglio="carroConvoglio"
        :isSender="false"
      />
    </v-sheet>
    <br />
    <br />
    <v-row no-gutters>
      <v-col md="2" offset-md="10">
        <v-spacer></v-spacer>
        <v-btn block x-large color="success" @click="saveConvoy"> Salva </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WagonManovreView from "./WagonManovreView";
import ToastMixin from "../../../mixins/ToastMixin";

export default {
  name: "WagonListManovreView",
  components: {
    WagonManovreView,
  },
  mixins: [ToastMixin],
  data() {
    return {};
  },
  props: {
    tripId: null,
    viaggioTreno: null,
    convoglioId: null,
    listCarriTerminal: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      listCarriConvoglio: [],
      convoglio: null,
    };
  },
  created() {
    this.$eventBus.$on("spostaCarro", (carroConvoglio, sender) => {
      if (sender && sender === true) {
        //we must move it down
        this.addWagonToNewConvoy(carroConvoglio);
      } else {
        //we must remove it from below
        this.removeWagonFromNewConvoy(carroConvoglio);
      }
    });
  },
  async mounted() {
    await this.fetchData();
    this.initComponent();
  },

  methods: {
    async fetchData() {
      this.loadCarriConvoglio();
    },

    initComponent() {},

    generaChiaveCarroConvoglio(id, isSender) {
      return " " + id + isSender;
    },

    async loadCarriConvoglio() {
      const convogli = await this.$api.trainData.findAllConvogliByViaggioTrenoPartenza(
        +this.tripId
      );
      if (convogli) {
        this.convoglio = convogli[0];
        this.listCarriConvoglio = this.convoglio.listCarri;
      } else {
        this.convoglio = null;
        this.listCarriConvoglio = [];
      }
    },

    addWagonToNewConvoy(carroConvoglio) {
      if (carroConvoglio && this.listCarriTerminal) {
        var index = 0;
        for (let i = 0; i < this.listCarriTerminal.length; i++) {
          if (this.listCarriTerminal[i].id === carroConvoglio.id) {
            break;
          }
          index++;
        }
      }
      if (index > -1) {
        this.listCarriTerminal.splice(index, 1);
      }

      this.listCarriConvoglio.push(carroConvoglio);
    },

    removeWagonFromNewConvoy(carroConvoglio) {
      if (carroConvoglio && this.listCarriConvoglio) {
        var index = 0;
        for (let i = 0; i < this.listCarriConvoglio.length; i++) {
          if (this.listCarriConvoglio[i].id === carroConvoglio.id) {
            break;
          }
          index++;
        }
      }

      this.listCarriConvoglio.splice(index, 1);
      this.listCarriTerminal.push(carroConvoglio);
    },

    spostaTuttiCarri() {
      console.log("Lunghezza " + this.listCarriTerminal.length);
      if (this.listCarriTerminal && this.listCarriTerminal.length > 0) {
        var sizeCarriTerminal = this.listCarriTerminal.length;
        for (let i = 0; i < sizeCarriTerminal; i++) {
          this.addWagonToNewConvoy(this.listCarriTerminal[0]); // because it gets modified so we always add the first one
        }
      }
    },

    async saveConvoy() {
      try {
        const newConvoyRef = await this.$api.trainData.creaAggiornaConvoglio(
          this.convoglio,
          this.tripId,
          this._.transform(
            this.listCarriConvoglio,
            (res, value) => {
              if (value.convoglioId != this.convoglioId) {
                res.push(value.id);
              }

              return res;
            },
            []
          )
        );
        this.loadCarriConvoglio();
        this.showSuccess("Convoglio salvato corretamente");
      } catch (error) {
        console.log("Errore saveConvoy", error);
        let message = "Aggiornamento convoglio non andato a buon fine";
        if (error.data) {
          message = error.data.message;
        }
        if (message.startsWith("javax.ws.rs.BadRequestException: "))
          message = message.substring(
            "javax.ws.rs.BadRequestException: ".length
          );
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.outer div {
  float: none;
  height: 100%;
  margin: 0 1%;
  display: inline-block;
}
</style>