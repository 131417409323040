import railsMixins from "./railsMixins";
import formatMixin from "../../../mixins/FormatsMixins";
import moment from "moment";

export default {
  data() {
    return {
      zoom: 1,
      tapEvCache: [],
      viaggio: {},
      carroViaggioSel: {},
      chargingValidationResult: null,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
    };
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  mixins: [railsMixins, formatMixin],
  filters: {
    formatDate(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM/YYYY");
    },
  },
  methods: {
    initComponent() {},
    getCarroWorkingState(carroViaggio) {
      return carroViaggio.stadioLavorazione ? carroViaggio.stadioLavorazione.toLowerCase() : "";
    },
    getCarroUtiCount(carroViaggio) {
      return carroViaggio.listUti ? carroViaggio.listUti.length : 0;
    },
    getCarroType(carroViaggio) {
      if (!carroViaggio.visitaCarro) return "";

      if (carroViaggio.visitaCarro.tipoCarro) {
        return carroViaggio.visitaCarro.tipoCarro.codice;
      } else if (carroViaggio.visitaCarro.carro && carroViaggio.visitaCarro.carro.tipoCarro) {
        return carroViaggio.visitaCarro.carro.tipoCarro.codice;
      }
      return "";
    },
    getCategoriaCarro(carroViaggio) {
      const carro = carroViaggio.visitaCarro.carro;
      if (!carro) return "";
      return carro.categoria;
    },
    getCarroIso(carroViaggio) {
      if (carroViaggio.visitaCarro && carroViaggio.visitaCarro.carro) {
        return carroViaggio.visitaCarro.carro.iso;
      }
      return "";
    },
    getCarroImo(carroViaggio) {
      if (carroViaggio.visitaCarro && carroViaggio.visitaCarro.carro) {
        return carroViaggio.visitaCarro.carro.imo;
      }
      return "";
    },
    getCarroLength(carroViaggio) {
      if (!carroViaggio.visitaCarro) return "";

      if (carroViaggio.visitaCarro.carro) {
        return carroViaggio.visitaCarro.carro.lunghezza || 20;
      } else if (carroViaggio.visitaCarro.tipoCarro) {
        return carroViaggio.visitaCarro.tipoCarro.lunghezza || 20;
      }
      return 20;
    },
    getCarroLengthMaxTeu(carroViaggio) {
      if (!carroViaggio.visitaCarro) return 0;

      if (carroViaggio.visitaCarro.carro) {
        return carroViaggio.visitaCarro.carro.lunghezzaMaxTeu || 0;
      } else if (carroViaggio.visitaCarro.tipoCarro) {
        return carroViaggio.visitaCarro.tipoCarro.lunghezzaMaxTeu || 0;
      }
      return 0;
    },
    getCarroLicensePlate(carroViaggio) {
      if (carroViaggio.visitaCarro) {
        return carroViaggio.visitaCarro.targa;
      }
      return "";
    },
    getCarroTara(carroViaggio) {
      if (carroViaggio.visitaCarro) {
        if (carroViaggio.visitaCarro.carro) {
          if (carroViaggio.visitaCarro.carro.tara) {
            return carroViaggio.visitaCarro.carro.tara;
          } else if (carroViaggio.visitaCarro.carro.tipoCarro && carroViaggio.visitaCarro.carro.tipoCarro.tara) {
            return carroViaggio.visitaCarro.carro.tipoCarro.tara;
          }
        }
        if (carroViaggio.visitaCarro.tipoCarro) {
          return carroViaggio.visitaCarro.tipoCarro.tara;
        }
      }
      return "";
    },
    getCarroPortataMax(carroViaggio) {
      if (carroViaggio.visitaCarro) {
        if (carroViaggio.visitaCarro.carro) {
          if (carroViaggio.visitaCarro.carro.portataMax) {
            return carroViaggio.visitaCarro.carro.portataMax;
          } else if (carroViaggio.visitaCarro.carro.tipoCarro && carroViaggio.visitaCarro.carro.tipoCarro.portataMax) {
            return carroViaggio.visitaCarro.carro.tipoCarro.portataMax;
          }
        }
        if (carroViaggio.visitaCarro.tipoCarro) {
          return carroViaggio.visitaCarro.tipoCarro.portataMax;
        }
      }
      return "";
    },
    getSumPesoUti(carroViaggio) {
      if (carroViaggio.listUti && carroViaggio.listUti.length > 0) {
        let pesoTot = 0;
        carroViaggio.listUti.forEach((el) => {
          pesoTot += el.visitaUti.pesoLordo !== null ? el.visitaUti.pesoLordo : 0;
        });
        return pesoTot;
      }
      return 0;
    },
    getPesoCarriViaggioConUti(carriViaggio) {
      var pesoTreno = 0;
      if (carriViaggio) {
        carriViaggio.forEach((carroViaggio) => {
          let carroTara = this.getCarroTara(carroViaggio);
          let sumPesoUti = this.getSumPesoUti(carroViaggio);
          pesoTreno += (carroTara !== "" ? carroTara : 0) + sumPesoUti;
        });
      }
      return pesoTreno===0?0:pesoTreno/1000;
    },
    getConvoglio(carroViaggio) {
      if (carroViaggio.visitaCarro && carroViaggio.visitaCarro.carroConvoglioList && carroViaggio.visitaCarro.carroConvoglioList.length > 0) {
        return carroViaggio.visitaCarro.carroConvoglioList[0].convoglio;
      }
      return undefined;
    },
    getBinario(carroViaggio) {
      var convoglio = this.getConvoglio(carroViaggio);
      if (convoglio) {
        return convoglio.binario;
      }
      return undefined;
    },
    getParcoBinari(carroViaggio) {
      var binario = this.getBinario(carroViaggio);
      if (binario) {
        return binario.parcoBinari;
      }
      return undefined;
    },
    getUtiCode(visitaUti) {
      return this.do_getUtiCode(visitaUti,'');
    },
    getUtiCodeExt(visitaUti) {
      return this.do_getUtiCode(visitaUti, visitaUti? visitaUti.company: null);
    },
    do_getUtiCode(visitaUti, cosmosValueEmpty) {
      if (!visitaUti) return "";
      if (!visitaUti.uti && this.hasCosmosConnector()) {
        return cosmosValueEmpty;
      }
      if (visitaUti.uti) {
        return visitaUti.uti.codice;
      } else if (visitaUti.tipoUti) {
        return visitaUti.tipoUti.codice;
      }
      return "";
    },
    getUtiType(visitaUti) {
      if (!visitaUti) return "";
      if (visitaUti.tipoUti) {
        // return visitaUti.tipoUti.descrizione;
        return visitaUti.tipoUti.codice;
      } else if (visitaUti.uti) {
        // && utiCarro.visitaUti.uti.tipoUti) {
        //return visitaUti.uti.tipoUti.descrizione;
        return visitaUti.uti.tipoUti.codice;
      }
      return "";
    },
    getUtiWeight(visitaUti) {
      if (!visitaUti) return "";
      if (visitaUti.pesoLordo) {
        return visitaUti.pesoLordo;
      }
      if (visitaUti.uti) {
        return visitaUti.uti.peso;
      } else if (visitaUti.tipoUti) {
        return visitaUti.tipoUti.peso;
      }
      return "";
    },
    isGreaterThan29Tons(visitaUti) {
      if (visitaUti) {
        let utiWeight = this.getUtiWeight(visitaUti);
        if ((utiWeight !== "" ? utiWeight : 0) > 29000) {
          return true;
        }
      }
      return false;
    },
    getUtiTareWeight(visitaUti) {
      if (!visitaUti) return "";
      if (visitaUti.uti) {
        return visitaUti.uti.tara;
      } else if (visitaUti.tipoUti) {
        return visitaUti.tipoUti.tara;
      }
      return "";
    },
    getUtiLength(visitaUti) {
      if (!visitaUti) return "";
      if (visitaUti.uti) {
        return visitaUti.uti.lunghezza;
      } else if (visitaUti.tipoUti) {
        if (visitaUti.tipoUti.lunghezzaEsterna) {
          return visitaUti.tipoUti.lunghezzaEsterna;
        } else if (visitaUti.tipoUti.lunghezzaPiedi) {
          return visitaUti.tipoUti.lunghezzaPiedi;
        }
      }
      return "";
    },
    getUtiStatus(visitaUti, viaggioId) {
      if (!visitaUti) return "";
      if (visitaUti.utiCarroList) {
        const utiCarro = this._.find(visitaUti.utiCarroList, (uc) => uc.carroViaggio && viaggioId === uc.carroViaggio.viaggioId);
        if (utiCarro) {
          return utiCarro.stato;
        }
      }
      return "N/A";
    },
    getUtiCarroFeatures(item) {
      if (item.carroViaggio && item.carroViaggio.listUti) {
        var filterList = item.carroViaggio.listUti.filter((lu) => lu.visitaUtiId === item.visitaUtiId);
        if (!filterList || filterList === null || filterList.length <= 0) return null;
        return filterList[0];
      } else {
        return null;
      }
    },
    getUtiCarroTassativoTipoCarro(item) {
      var obj = this.getUtiCarroFeatures(item);
      if (obj === null) {
        return null;
      }
      return obj.tassativoTipoCarro;
    },
    getUtiCarroTassativoCarro(item){
        var obj = this.getUtiCarroFeatures(item);
        if (obj === null) {
          return null;
        }
        return obj.tassativoCarro;
    },
    getUtiCarroPortaAPorta(item){
        var obj = this.getUtiCarroFeatures(item);
        if (obj === null) {
          return null;
        }
        return obj.portaAPorta;
    },
    getUtiDangerousFlag(visitaUti) {
      if (!visitaUti) return false;
      if (visitaUti.uti) {
        return visitaUti.uti.pericoloso;
      } else if (visitaUti.tipoUti) {
        return visitaUti.tipoUti.pericoloso;
      }
      return false;
    },
    getFullEmpty(visitaUti) {
      if (visitaUti) {
        if (visitaUti.fullEmpty === "F") {
          return "Full";
        } else if (visitaUti.fullEmpty === "E") {
          return "Empty";
        }
      }
      return "";
    },
    reorderUti(carroViaggio) {
      let pos = 1;
      carroViaggio.listUti.forEach((uc) => {
        uc.posizioneCarroViaggio = pos++;
      });
    },
    async joinBookingWagon(booking, listCarri) {
      try {
        const carroViaggio = this._.find(listCarri, (cv) => this._.find(cv.listUti, (uc) => uc.visitaUti.id === booking.visitaUtiId));
        this.reorderUti(carroViaggio);
        this.chargingValidationResult = await this.$api.trainData.associaUtiCarro(booking, carroViaggio);
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        throw e;
      }
    },
    async unjoinUtiFromWagon(utiCarro) {
      try {
        this.chargingValidationResult = null;
        await this.$api.trainData.cancellaUtiCarro(utiCarro);
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        throw e;
      }
    },
    async unjointUtiFromViaggio(bookingId) {
      try {
        this.chargingValidationResult = null;
        await this.$api.trainData.cancellaBooking(bookingId);
      } catch (e) {
        this.showError("aggiornamento pianificazione non andato a buon fine");
        throw e;
      }
    },
  },
  computed: {
    isPlanned() {
      return this.viaggio && (this.viaggio.pianificato === true || this.viaggio.statoOperativo == "CHIUSO");
    },
    isWorked() {
      return (
        this.viaggio && this.viaggio.statoOperativo && (this.viaggio.statoOperativo.toLowerCase() === "finelavorazione" || this.viaggio.statoOperativo.toLowerCase() === "chiuso")
      );
    },
  },
};
