<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Composizione Treno di Partenza da {{ viaggio.nodoPartenza | codice }} a
        {{ viaggio.nodoArrivo | codice }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="infoPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="text-body-1 text-uppercase font-weight-regular py-2 panelheader"
            >
              Dati viaggio
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TripInfoPanelFull :viaggio="viaggio" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs color="primary" v-model="tabExecution">
          <v-tab>Gestione Carri</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabExecution" touchless>
          <v-tab-item>
            <v-row>
              <v-col sm="6" md="3">
                <v-combobox
                  clearable
                  v-model="selectedPark"
                  :items="parks"
                  label="Parco binari"
                  item-text="codice"
                  @change="loadBinari()"
                />
              </v-col>

              <v-col sm="6" md="3">
                <v-combobox
                  clearable
                  v-model="binario"
                  :items="binari"
                  label="Binario"
                  item-text="descrizione"
                />
              </v-col>

              <v-col sm="6" md="3">
                <v-text-field label="Targa carro" v-model="targaCarro" />
              </v-col>

              <v-col sm="6" md="3">
               <v-btn block x-large color="success" @click="doSearchCarri" small
                  >CERCA</v-btn
                >
              </v-col>
            </v-row>

            <v-card>
              <v-card-title
                class="text-body-1 text-uppercase font-weight-regular py-2 my-2 panelheader"
              >
                COMPOSIZIONE CONVOGLIO
              </v-card-title>
              <v-card-text>
                <WagonListManovreView
                  :tripId="tripId"
                  :viaggioTreno="viaggio"
                  :listCarriTerminal="listCarriTerminal"
                  :convoglioId="convoglioId"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToastMixin from "../../../mixins/ToastMixin";
import MultiParkManagementMixins from "../../../mixins/MultiParkManagementMixins";

import WagonListManovreView from "../../../components/rails/manovre/WagonListManovreView";

import TripVisualizationMixins from "../../../components/rails/mixins/tripVisualizationMixins";
import TripInfoPanelFull from "../../../components/misc/TripInfoPanelFull";

export default {
  name: "ComposizioneTrenoPartenza",
  components: {
    WagonListManovreView,
    TripInfoPanelFull,
  },
  mixins: [ToastMixin, TripVisualizationMixins, MultiParkManagementMixins],
  props: {
      tripId : null,
  },
  filters: {
    codice: function (value) {
      if (value) return value.codice;
    },
  },
  data() {
    return {
      binario: null,
      targaCarro: null,
      listCarriTerminal : null,
      convoglio : null,
      convoglioId : null,
      viaggio: {},
      convogli: [],
      binari: null,

      infoPanel: [0],
      tabExecution: null,
    };
  },
  created() {
    this.$eventBus.$on("convoyReload", async () => {
      await this.fetchData();
    });
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {},
  methods: {
    async fetchData() {
      try {
        await this.loadParks();
        this.viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.convogli = await this.$api.trainData.findAllConvogliByViaggioTrenoPartenza(+this.tripId);

        if (this.convogli){
            this.convoglio = this.convogli[0];
            this.convoglioId = this.convoglio.id;
        }else{
            this.convoglio = null;
            this.convoglioId = null;
            this.listCarriConvoglio = [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadBinari() {
        this.binari = [];
        if (this.selectedPark){
            this.binari = await this.$api.railData.getBinariByParco(
        this.selectedPark.id
      );
        }
      
    },
    async searchCarriConvoglio(viaggioId, convoglioId, parcoBinarioId, binarioId, targaCarro) {
        const targaCarroRequest = targaCarro && targaCarro != "" ? targaCarro : "null";
        var queryString = "viaggio/" + viaggioId +"/convoglio/" + convoglioId+"/parco/" + parcoBinarioId + "/binario/"+binarioId+"/targa/"+targaCarroRequest;
        const fullPath = this.$apiConfiguration.BASE_PATH+"carriConvoglio/" + queryString;
        return this.$api.get(fullPath);
    },
    async doSearchCarri() {
        if (this.selectedPark
            && this.binario){
                this.listCarriTerminal = [];
                var listCarriCaricato = await this.searchCarriConvoglio(this.viaggio.id, +this.convoglioId,  this.selectedPark.id, this.binario.id, this.targaCarro);
                if (listCarriCaricato && listCarriCaricato != ""){
                    this.listCarriTerminal = listCarriCaricato;
                }
                }else{
                if (!this.selectedPark){
                    const msg = "Parco binari obbligatorio !" ;
                    this.showError(msg);
                }
                if (!this.binario){
                    const msg = "Binario obbligatorio !";
                    this.showError(msg);
                }
            
                return [];
            }
       }, 
  },
};
</script>

<style scoped>
</style>
