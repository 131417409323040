<template>
  <v-card @click="spostaCarro(carroConvoglio)">
    <!-- HEADER-->
    <v-app-bar flat :color="getClassCarroConvoglio(carroConvoglio)">
      <v-toolbar-title class="text-h5 font-weight-black">{{
        carroConvoglio.visitaCarro.targa
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large v-if="isCarroLocked(carroConvoglio)">mdi-lock</v-icon>
      <v-icon large v-else>mdi-lock-open</v-icon>
      <v-chip :class="getClassViaggioTreno()"
        ><b>{{ this.labelViaggioPartenza }}</b></v-chip
      >
      <!-- <v-chip class="text-body-1 header ma-1"
        ><b>{{
          carroConvoglio.cancellato ? "" : carroConvoglio.posizione
        }}</b></v-chip
      > -->
    </v-app-bar>

    <!-- INFO CARRO -->
    <v-card-text class="grey lighten-2">
      <v-container class="pa-0 ma-0">
        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-sheet
              elevation="1"
              rounded="lg"
              class="text-body-1 font-weight-regular pa-3 ma-3"
            >
              <p class="wagon-details">
                Tipo: <b>{{ getCarroType(carroConvoglio) }}</b>
                <br />
                ISO: <b>{{ getCarroIso(carroConvoglio) }}</b> - IMO:
                <b>{{ getCarroImo(carroConvoglio) }}</b>
                <br />
                <v-icon>mdi-weight</v-icon>
                {{ getCarroTara(carroConvoglio) }} Kg
                <br />
                <v-icon>mdi-tape-measure</v-icon>
                {{ getCarroLength(carroConvoglio) }} m
                <br />
                <v-icon>mdi-plus-box-outline</v-icon>
                posizione: {{ posizioneBinario(carroConvoglio) }} m
                <br />
              </p>
            </v-sheet>
          </v-col>
        </v-row>

        <!-- UTI DEL CARRO -->
        <!-- <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col class="pa-0 ma-0" v-if="listUti && listUti.length > 0">
            <v-sheet
              elevation="1"
              rounded="lg"
              class="text-body-1 font-weight-regular pa-3 ma-3"
            >
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" justify="center" v-for="utiCarro in listUti"
                :key="utiCarro.id">
                  <v-col
                    cols="12"
                    v-if="utiCarro.visitaUti && utiCarro.visitaUti.uti"
                    class="font-weight-bold pa-0 ma-0"
                    >{{ utiCarro.visitaUti.uti.codice }}</v-col
                  >
                  <v-col cols="12" v-else class="font-weight-bold pa-0 ma-0">{{
                    utiCarro.visitaUti.merce
                  }}</v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col class="pa-0 ma-0" v-else>
            <v-sheet
              elevation="1"
              rounded="lg"
              class="text-body-1 font-weight-regular pa-3 ma-3"
            >
              NO UTI
            </v-sheet>
          </v-col>
        </v-row> -->

        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col class="pa-0 ma-0" v-if="listUti && listUti.length > 0">
            <v-sheet
              elevation="1"
              rounded="lg"
              :class="`text-body-1 font-weight-regular pa-3 ma-3 ${getClass(
                utiCarro
              )}`"
              v-for="utiCarro in listUti"
              :key="utiCarro.id"
            >
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col
                    cols="12"
                    v-if="utiCarro.visitaUti && utiCarro.visitaUti.uti"
                    class="font-weight-bold pa-0 ma-0"
                    >{{ utiCarro.visitaUti.uti.codice }}</v-col
                  >
                  <v-col cols="12" v-else class="font-weight-bold pa-0 ma-0">{{
                    utiCarro.visitaUti.merce
                  }}</v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col class="pa-0 ma-0" v-else>
            <v-sheet
              elevation="1"
              rounded="lg"
              class="text-body-1 font-weight-regular pa-3 ma-3"
            >
              NO UTI
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import railsMixins from "../mixins/railsMixins";
import tripVisualizationMixins from "../mixins/tripVisualizationMixins";

export default {
  name: "WagonManovreView",
  components: {},
  mixins: [railsMixins, tripVisualizationMixins],
  data() {
    return {
      utiList: [],
    };
  },
  props: {
    carroConvoglio: {
      type: Object,
      required: true,
    },
    isSender: {
      type: Boolean,
      required: true,
    },
    viaggioTreno: null,
    tripId: null,
    convoglioId: null,
  },
  mounted() {
    this.utiList = this.listUti;
  },
  computed: {
    listUti() {
      var listUti = [];
      //CARRI DA CONVOGLIO
      if (
        this.carroConvoglio &&
        this.carroConvoglio.listUti &&
        this.carroConvoglio.listUti.length > 0
      ) {
        listUti = this.carroConvoglio.listUti.filter(utiWagon => utiWagon.tipoMovimento.toLowerCase() === "import");
      }

      //CARRI RICERCATI
      if (
        this.carroConvoglio &&
        this.carroConvoglio.listUti &&
        this.carroConvoglio.listUti.length > 0
      ) {
        listUti = this.carroConvoglio.listUti.filter(utiWagon => utiWagon.tipoMovimento.toLowerCase() === "import");
      }
      return listUti;
    },
    labelViaggioPartenza() {
      if (this.carroConvoglio) {
        if (
          this.carroConvoglio.viaggioOut &&
          this.carroConvoglio.viaggioOut.nodoArrivo
        ) {
          return this.carroConvoglio.viaggioOut.nodoArrivo.descrizione;
        } else return "";
      }
    },
  },
  methods: {
    spostaCarro(carroConvoglio) {
      if (!this.isCarroLocked(carroConvoglio)) {
        this.$eventBus.$emit("spostaCarro", carroConvoglio, this.isSender);
      } else {
        this.showWarning(
          "Non si puo spostare il carro. Gia Associato al convoglio !"
        );
      }
    },
    posizioneBinario(carroConvoglio) {
      if (!isNaN(carroConvoglio.posizioneBinario)) {
        return parseFloat(carroConvoglio.posizioneBinario).toFixed(2);
      }
      return 0;
    },

    getClassViaggioTreno() {
      if (
        this.viaggioTreno &&
        this.viaggioTreno.id &&
        this.carroConvoglio &&
        this.carroConvoglio.viaggioOut &&
        this.carroConvoglio.viaggioOut &&
        this.carroConvoglio.viaggioOut.id === this.viaggioTreno.id
      ) {
        return "green lighten-1";
      } else {
        return "text-body-1 header ma-1";
      }
    },
    isCarroLocked(carroConvoglio) {
      if (this.isSender) {
        return false;
      }

      if (
        this.convoglioId &&
        carroConvoglio &&
        this.convoglioId === carroConvoglio.convoglioId
      ) {
        return true;
      }

      return false;
    },
    getClass(utiCarro) {
      if (
        utiCarro.remainOnBoardScarico &&
        utiCarro.remainOnBoardScarico === true
      ) {
        return "uti-rob";
      } else if (utiCarro.rifiutato === true) {
        return "red lighten-2";
        // } else if (utiCarro.stato==="SCARICATO") {
        //   return "teal lighten-2";
      } else if (utiCarro.stato === "CANCELLATO") {
        return "grey lighten-1";
      } else {
        return this.getUtiStateColorText(utiCarro);
      }
    }

    //FINISH MY METHODS
    ,
    getClassCarroConvoglio(carroConvoglio) {
      if (carroConvoglio.cancellato) {
        return "grey lighten-1";
      } else {
        return "warning";
      }
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(
    -45deg,
    #ffffff,
    #ffffff 5px,
    rgb(196, 196, 192) 5px,
    rgb(196, 196, 192) 10px
  ) !important;
}
</style>