import { render, staticRenderFns } from "./WagonManovreView.vue?vue&type=template&id=58958470&scoped=true"
import script from "./WagonManovreView.vue?vue&type=script&lang=js"
export * from "./WagonManovreView.vue?vue&type=script&lang=js"
import style0 from "./WagonManovreView.vue?vue&type=style&index=0&id=58958470&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58958470",
  null
  
)

export default component.exports